import { cx, dictToList, getStandardDateFormat, queryString } from "utilities";
import styles from "../../Panel.module.css";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { useQuery } from "hooks";
import { MdiBottomPanelClose } from "components/miloDesignSystem/atoms/icons/MdiBottomPanelClose";
import {
  GroupedUnitInProgress,
  ManufacturingUnitGroupDetails,
  ManufacturingUnitGroupPriority,
  ManufacturingUnitGroupType,
} from "api/manufacturing/units/models";
import { manufacturingStagesConstants } from "constants/manufacturingStages";
import { Button } from "components/miloDesignSystem/atoms/button";
import { InfoLabel } from "components/common/infoLabel";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";
import {
  EMPTY_VALUE,
  EmptyValue,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { AttributeCategory } from "api/manufacturing/schemas/models";
import { MdiCancel } from "components/miloDesignSystem/atoms/icons/MdiCancel";
import { manufacturingUnitsActions } from "api/manufacturing/units/actions";
import { useState } from "react";
import { Table } from "components/miloDesignSystem/molecules/table";
import { useGroupedUnitsColumns } from "./useGroupedUnitsColumns";
import { comfortableListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { EmptySection } from "components/common/emptySection/EmptySection";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { Select } from "components/miloDesignSystem/molecules/select";
import { FileDownloadHandler } from "components/miloDesignSystem/atoms/fileDownloadHandler";
import { manufacturingFileFactory } from "api/manufacturingNew/calls";
import { MdiStacks } from "components/miloDesignSystem/atoms/icons/MdiStacks";

interface Props {
  unitInProgressDetails: ManufacturingUnitGroupDetails;
}

export const InProgress = ({ unitInProgressDetails }: Props) => {
  if (unitInProgressDetails.type === ManufacturingUnitGroupType.GROUP)
    return <GroupInProgress unitInProgressDetails={unitInProgressDetails} />;
  return <UnitInProgress unitInProgressDetails={unitInProgressDetails} />;
};

const UnitInProgress = ({ unitInProgressDetails }: Props) => {
  const { updateQuery } = useQuery();
  const priorityOptions = dictToList(
    manufacturingStagesConstants.manufacturingUnitGroupPriorityDict,
  ).map(({ key, value }) => ({
    icon: value.dark.icon,
    text: value.dark.label,
    type: MenuItemType.ICON,
    value: key,
  }));

  return (
    <div className={cx(styles.panel, "pb-2")}>
      <div
        className={cx(
          styles.ticketPanelHeader,
          styles.inProgressSingleUnitHeader,
          "d-flex align-items-center justify-content-between gap-2 px-3 py-2",
        )}
      >
        <Typography
          color={
            manufacturingStagesConstants.manufacturingUnitGroupPriorityDict[
              unitInProgressDetails.priority
            ].dark.color
          }
          fontSize="16"
          fontWeight="700"
          noWrap
        >
          {unitInProgressDetails.signature}
        </Typography>
        <Typography color="neutralWhite64" fontSize="14" fontWeight="700" noWrap>
          {unitInProgressDetails.group?.signature}
        </Typography>
        <div className="d-flex align-items-center justify-content-end gap-2">
          <Select.Async
            items={priorityOptions}
            mutationHook={manufacturingUnitsActions.useInProgressManufacturingUnitPatch}
            transformQueryData={priority => ({
              id: unitInProgressDetails.id,
              toUpdate: {
                priority: priority as ManufacturingUnitGroupPriority,
              },
            })}
            selected={unitInProgressDetails.priority}
            theme="dark"
          />
          <IconButton icon={MdiCancel} theme="dark" variant="transparent" />
          <FileDownloadHandler
            factoryFn={() =>
              manufacturingFileFactory.manufacturingItemPdf(
                [unitInProgressDetails.manufacturingItem.id],
                unitInProgressDetails.signature,
              )
            }
            type="pdf"
          >
            {({ download, isLoading }) => (
              <Tooltip title="Pobierz etykietę zlecenia">
                <IconButton
                  icon={MdiQrCode}
                  isLoading={isLoading}
                  onClick={download}
                  theme="dark"
                  variant="transparent"
                />
              </Tooltip>
            )}
          </FileDownloadHandler>
          <span className="line-divider" />
          <IconButton
            icon={MdiBottomPanelClose}
            onClick={() => updateQuery({ unitPanelId: "" })}
            theme="dark"
            variant="transparent"
          />
        </div>
      </div>
      <div className={cx(styles.todoPanelContent, "d-flex flex-column px-3 py-2 gap-2")}>
        <div className="w-100 nowrap">
          <div className="d-flex align-items-center gap-2 nowrap">
            {unitInProgressDetails.attributes
              .filter(attribute => attribute.category === AttributeCategory.PRODUCT)
              .map(attribute => (
                <Typography fontSize="16" fontWeight="700" key={attribute.attribute.id} noWrap>
                  {attribute.value.name}
                </Typography>
              ))}
          </div>
          <div
            className={cx(
              styles.panelAttributes,
              "d-flex align-items-center gap-2 cut-text col-12 pl-0 pr-0",
            )}
          >
            {unitInProgressDetails.attributes
              .filter(attribute => attribute.category !== AttributeCategory.PRODUCT)
              .map(attribute => (
                <Typography fontSize="14" fontWeight="600" key={attribute.attribute.id}>
                  {attribute.value.name}
                </Typography>
              ))}
          </div>
        </div>
        <InfoLabel title="zamówienie">
          <Typography color="neutralBlack88" fontSize="14" fontWeight="700">
            {unitInProgressDetails.order ? unitInProgressDetails.order.signature : EMPTY_VALUE}
          </Typography>
        </InfoLabel>
        <InfoLabel title="zrealizować do">
          <Typography color="neutralBlack88" fontSize="14" fontWeight="700">
            {unitInProgressDetails.scheduledAt
              ? getStandardDateFormat(unitInProgressDetails.scheduledAt)
              : EMPTY_VALUE}
          </Typography>
        </InfoLabel>
        <InfoLabel title="krojczy/-a">
          {unitInProgressDetails.employee ? (
            <div className="d-flex align-items-center gap-1">
              <Avatar size="sm" user={unitInProgressDetails.employee} theme="light" />
              <Typography className="ml-1" fontSize="12" fontWeight="700" noWrap>
                {unitInProgressDetails.employee.firstName} {unitInProgressDetails.employee.lastName}
              </Typography>
            </div>
          ) : (
            <EmptyValue color="neutralBlack88" fontSize="14" fontWeight="700" />
          )}
        </InfoLabel>
        {unitInProgressDetails.isDeclined && (
          <div className="d-flex flex-column gap-2 py-2">
            <InfoLabel title="Do poprawy">
              <div />
            </InfoLabel>
            <div className={styles.declinedNote}>
              <Typography color="neutralBlack88" fontSize="14" fontWeight="500">
                {unitInProgressDetails.note || EMPTY_VALUE}
              </Typography>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const GroupInProgress = ({ unitInProgressDetails }: Props) => {
  const { updateQuery } = useQuery();
  const [selectedUnits, setSelectedUnits] = useState<GroupedUnitInProgress[]>([]);
  const search = queryString.stringify({
    manufacturingWorkingUnitGroup: unitInProgressDetails.id,
  });
  const {
    data: groupedUnits,
    error,
    isLoading,
    isPreviousData,
  } = manufacturingUnitsActions.useGetInProgressGroupedUnits(search);
  const columns = useGroupedUnitsColumns(groupedUnits, selectedUnits, setSelectedUnits);
  const setUnitsAsFinishedMutation = manufacturingUnitsActions.useSetManufacturingUnitsAsFinished();

  return (
    <div className={cx(styles.panel, "pb-2")}>
      <div
        className={cx(
          styles.ticketPanelHeader,
          styles.groupPanelHeader,
          "d-flex align-items-center justify-content-between gap-2 px-3",
        )}
      >
        <div className={cx(styles.groupHeaderAttributes, "d-flex align-items-center gap-2")}>
          <Typography
            className={styles.groupHeaderSignature}
            color="neutralWhite64"
            fontSize="16"
            fontWeight="700"
            noWrap
          >
            {unitInProgressDetails.signature}
          </Typography>
          <div className="d-flex align-items-center gap-1">
            <MdiStacks color="neutralWhite100" size="14" />
            <Typography color="neutralWhite100" fontSize="14" fontWeight="600" noWrap>
              {unitInProgressDetails.manufacturingItems.length}
            </Typography>
          </div>
          <div className="d-flex align-items-center gap-2 nowrap">
            {unitInProgressDetails.attributes.map(attribute => (
              <Typography
                color="neutralWhite100"
                fontSize="14"
                fontWeight="600"
                key={`inProgress-${attribute.attribute.id}`}
              >
                {attribute.value.name}
              </Typography>
            ))}
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-end gap-2">
          <FileDownloadHandler
            factoryFn={() =>
              manufacturingFileFactory.manufacturingItemPdf(
                unitInProgressDetails.manufacturingItems.map(item => item.id),
                unitInProgressDetails.signature,
              )
            }
            type="pdf"
          >
            {({ download, isLoading }) => (
              <Tooltip title="Pobierz etykiety dla grupy">
                <IconButton
                  icon={MdiQrCode}
                  isLoading={isLoading}
                  onClick={download}
                  theme="dark"
                  variant="transparent"
                />
              </Tooltip>
            )}
          </FileDownloadHandler>
          <span className="line-divider" />
          <IconButton
            icon={MdiBottomPanelClose}
            onClick={() => updateQuery({ unitPanelId: "" })}
            theme="dark"
            variant="transparent"
          />
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between gap-2 px-3 py-2">
        {Boolean(selectedUnits.length) && (
          <div className="d-flex align-items-center gap-1">
            <Typography color="neutralBlack48" fontSize="12" fontWeight="400">
              wybrano:
            </Typography>
            <Typography color="neutralBlack88" fontSize="14" fontWeight="800">
              {selectedUnits.length}
            </Typography>
          </div>
        )}
        <div className="d-flex align-items-center w-100 justify-content-end gap-2">
          <Button
            className="text-uppercase"
            disabled={!Boolean(selectedUnits.length)}
            onClick={() =>
              setUnitsAsFinishedMutation.mutate({
                manufacturingWorkingUnitsIds: selectedUnits.map(unit => unit.id),
              })
            }
            size="small"
            variant="gray"
          >
            Przenieś do "gotowe"
          </Button>
        </div>
      </div>
      <div className={styles.groupTableWrapper}>
        <Table
          columns={columns}
          rows={groupedUnits}
          isLoading={isLoading || isPreviousData}
          error={error}
          uiSchema={comfortableListUiSchema}
          overrides={() => {
            return {
              hideHeader: !groupedUnits.length,
              noResultComponent: <EmptySection label="Brak zleceń" />,

              row: row => {
                return {
                  className: cx({
                    [styles.skullUrgent]: row.priority === ManufacturingUnitGroupPriority.A,
                    [styles.flameUrgent]: row.priority === ManufacturingUnitGroupPriority.B,
                  }),
                };
              },
            };
          }}
        />
      </div>
    </div>
  );
};
