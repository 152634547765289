import { createPaginatedKey } from "api/keys";
import { UUID } from "api/types";

export const manufacturingSchemasKeys = {
  manufacturingStage: {
    list: createPaginatedKey("manufacturingStages"),
    details: (id: UUID) => ["manufacturingStage", id],
    unitsGroups: (search: string) => ["manufacturingUnitsGroups", search],
    unitGroup: (unitGroupId: UUID) => ["manufacturingUnitGroup", unitGroupId],
  },
};
