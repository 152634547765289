import { IndexDetails } from "api/manufacturing/indexes/models";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiSchemeAlt } from "components/miloDesignSystem/atoms/icons/MdiSchemeAlt";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { Select } from "components/miloDesignSystem/molecules/select";
import { useSelector, useToggle } from "hooks";
import { useState } from "react";

interface Props {
  indexDetails: {
    initialSchema: IndexDetails["manufacturingSchema"];
    name: string;
    signature: string;
  };
  children: ({
    close,
    schema,
  }: {
    close: () => void;
    schema: IndexDetails["manufacturingSchema"];
  }) => React.ReactNode;
}

interface ModalProps extends Props {
  close: () => void;
}

export const AssignSchema = ({ children, indexDetails }: Props) => {
  const changeSchemaModal = useToggle();

  return (
    <>
      <Button
        className="text-uppercase"
        endIcon={MdiSchemeAlt}
        onClick={changeSchemaModal.open}
        size="small"
        variant="transparent"
      >
        zmień schemat
      </Button>
      {changeSchemaModal.isOpen && (
        <ChangeSchemaModal
          children={children}
          close={changeSchemaModal.close}
          indexDetails={indexDetails}
        />
      )}
    </>
  );
};

const ChangeSchemaModal = ({ children, close, indexDetails }: ModalProps) => {
  const manufacturingSchemas = useSelector(store => store.partials.manufacturingSchemas);
  const schemasOptions = manufacturingSchemas.map(schema => ({
    text: `${schema.name} (${schema.signature})`,
    type: MenuItemType.TEXT,
    value: schema.id,
  }));
  const [selectedSchema, setSelectedSchema] = useState<IndexDetails["manufacturingSchema"]>(
    indexDetails.initialSchema,
  );

  return (
    <Modal
      close={close}
      isOpen
      width={640}
      title={
        <Typography fontSize="20" fontWeight="700">
          {selectedSchema ? "Zmień" : "Dodaj"} schemat produkcji dla wariantu
        </Typography>
      }
    >
      <div>
        <div className="py-2 px-3 d-flex flex-column gap-2">
          <div className="pb-2">
            <Typography color="neutralBlack88" fontSize="16" fontWeight="700">
              {indexDetails.name || indexDetails.signature}
            </Typography>
          </div>
          <div className="mb-3">
            <Select
              items={schemasOptions}
              onChange={schemaId => {
                const manufacturingSchema = manufacturingSchemas.find(
                  schema => schema.id === schemaId,
                );
                if (manufacturingSchema) setSelectedSchema(manufacturingSchema);
              }}
              selected={selectedSchema?.id ?? null}
            />
          </div>
        </div>
        <div className="d-flex align-items-center gap-2 p-3">
          <Button className="text-uppercase" onClick={close} size="small" variant="transparent">
            Anuluj
          </Button>
          {children({ close, schema: selectedSchema })}
        </div>
      </div>
    </Modal>
  );
};
