import { Switch, RouteComponentProps, Redirect } from "react-router-dom";
import { Route } from "components/utils";
import { NotFound } from "pages/notFound";
import { ManufacturingStagesList } from "pages/manufacturingNew/manufacturingStages/ManufacturingStagesList";
import { StageBoard } from "pages/manufacturingNew/manufacturingStages/stageBoard/StageBoard";

export const ProductionOrdersRouting = ({ match }: RouteComponentProps) => {
  return (
    <Switch>
      <Route
        exact={true}
        path={`${match.path}`}
        render={() => <Redirect to={`${match.path}/list/all`} />}
      />
      <Route
        exact={true}
        path={`${match.path}/list`}
        render={() => <Redirect to={`${match.path}/list/all`} />}
      />
      <Route
        exact={true}
        path={`${match.path}/list/:tab`}
        component={ManufacturingStagesList}
        title="Milo - zlecenia produkcyjne"
      />
      <Route
        exact={true}
        path={`${match.path}/stage-orders/:stageId`}
        component={StageBoard}
        title="Milo - zlecenia produkcyjne"
      />
      <Route exact={true} path="*" component={NotFound} title="Milo" />
    </Switch>
  );
};
