import { manufacturingSchemaActions } from "api/manufacturing/schemas/actions";
import { ManufacturingStage } from "api/manufacturing/schemas/models";
import { Radio } from "components/miloDesignSystem/atoms/radio";
import { RightPanelSection } from "components/utils/drawer";

interface Props {
  manufacturingStage: ManufacturingStage;
}

export const StageSettingsSection = ({ manufacturingStage }: Props) => {
  return (
    <div className="d-flex flex-column py-2">
      <RightPanelSection title="W tym etapie:">
        <div className="mb-2">
          <Radio.Async
            mutationHook={manufacturingSchemaActions.usePatchManufacturingStage}
            transformQueryData={value => {
              if (value) {
                return {
                  id: manufacturingStage.id,
                  isQaStage: true,
                  isThisTheLastStage: false,
                };
              }
              return {
                id: manufacturingStage.id,
                isQaStage: false,
              };
            }}
            checked={manufacturingStage.isQaStage}
            label="Następuje sprawdzenie jakości"
            helperText="Jego efektem jest potwierdzenie jakości produktu lub zgłoszenie wymaganych poprawek"
            size="sm"
          />
        </div>
        <div>
          <Radio.Async
            mutationHook={manufacturingSchemaActions.usePatchManufacturingStage}
            transformQueryData={value => {
              if (value) {
                return {
                  id: manufacturingStage.id,
                  isThisTheLastStage: true,
                  isQaStage: false,
                };
              }
              return {
                id: manufacturingStage.id,
                isThisTheLastStage: false,
              };
            }}
            checked={manufacturingStage.isThisTheLastStage}
            label="Powstaje gotowy produkt"
            size="sm"
          />
        </div>
      </RightPanelSection>
    </div>
  );
};
