import {
  ManufacturingUnit,
  ManufacturingUnitGroup,
  ManufacturingUnitGroupPriority,
} from "api/manufacturing/units/models";
import { QueryParams, UUID } from "api/types";
import { DraggableStateSnapshot, DraggingStyle, NotDraggingStyle } from "react-beautiful-dnd";
import { dateFns, queryString } from "./utilities";
import { BoardFormat } from "api/manufacturing/schemas/models";
import { manufacturingStagesConstants } from "constants/manufacturingStages";
import { isBefore, isSameDay } from "date-fns";
import { StageBoardAttributeKind, StageBoardDefaultAttributesKind } from "api/manufacturing/models";

const getSortedManufacturingUnitGroups = (
  units: ManufacturingUnitGroup[] | null,
): Record<string, ManufacturingUnitGroup[]> => {
  if (!units) return { "": [] };
  const now = new Date();
  const todayUntil13 = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 13, 0, 0, 0);
  const todayUntil10 = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 10, 0, 0, 0);
  const todayMidnight = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);

  return {
    "": units.filter(unit => new Date(unit.startedAt) > todayUntil13),
    "Dzisiaj do 13:00": units.filter(
      unit => new Date(unit.startedAt) < todayUntil13 && new Date(unit.startedAt) > todayUntil10,
    ),
    "Dzisiaj do 10:00": units.filter(
      unit => new Date(unit.startedAt) < todayUntil10 && new Date(unit.startedAt) > todayMidnight,
    ),
    Wczoraj: units.filter(unit => dateFns.isYesterday(new Date(unit.startedAt))),
    [`${dateFns.format(dateFns.subDays(now, 2), "d.MM.yyyy")}`]: units.filter(unit =>
      isSameDay(new Date(unit.startedAt), dateFns.subDays(now, 2)),
    ),
    [`${dateFns.format(dateFns.subDays(now, 3), "d.MM.yyyy")}`]: units.filter(unit =>
      isSameDay(new Date(unit.startedAt), dateFns.subDays(now, 3)),
    ),
    [`${dateFns.format(dateFns.subDays(now, 4), "d.MM.yyyy")}`]: units.filter(unit =>
      isSameDay(new Date(unit.startedAt), dateFns.subDays(now, 4)),
    ),
    [`${dateFns.format(dateFns.subDays(now, 5), "d.MM.yyyy")}`]: units.filter(unit =>
      isSameDay(new Date(unit.startedAt), dateFns.subDays(now, 5)),
    ),
    [`${dateFns.format(dateFns.subDays(now, 6), "d.MM.yyyy")}`]: units.filter(unit =>
      isSameDay(new Date(unit.startedAt), dateFns.subDays(now, 6)),
    ),
    [`${dateFns.format(dateFns.subDays(now, 7), "d.MM.yyyy")}`]: units.filter(unit =>
      isSameDay(new Date(unit.startedAt), dateFns.subDays(now, 7)),
    ),
    " ": units.filter(unit => isBefore(new Date(unit.startedAt), dateFns.subDays(now, 7))),
  };
};

const getSortedManufacturingUnits = (
  units: ManufacturingUnit[] | null,
): Record<string, ManufacturingUnit[]> => {
  if (!units) return { "": [] };
  const now = new Date();
  const todayUntil13 = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 13, 0, 0, 0);
  const todayUntil10 = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 10, 0, 0, 0);
  const todayMidnight = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);

  return {
    "": units.filter(unit => new Date(unit.finishedAt) > todayUntil13),
    "Dzisiaj do 13:00": units.filter(
      unit => new Date(unit.finishedAt) < todayUntil13 && new Date(unit.finishedAt) > todayUntil10,
    ),
    "Dzisiaj do 10:00": units.filter(
      unit => new Date(unit.finishedAt) < todayUntil10 && new Date(unit.finishedAt) > todayMidnight,
    ),
    Wczoraj: units.filter(unit => dateFns.isYesterday(new Date(unit.finishedAt))),
    [`${dateFns.format(dateFns.subDays(now, 2), "d.MM.yyyy")}`]: units.filter(unit =>
      isSameDay(new Date(unit.finishedAt), dateFns.subDays(now, 2)),
    ),
    [`${dateFns.format(dateFns.subDays(now, 3), "d.MM.yyyy")}`]: units.filter(unit =>
      isSameDay(new Date(unit.finishedAt), dateFns.subDays(now, 3)),
    ),
    [`${dateFns.format(dateFns.subDays(now, 4), "d.MM.yyyy")}`]: units.filter(unit =>
      isSameDay(new Date(unit.finishedAt), dateFns.subDays(now, 4)),
    ),
    [`${dateFns.format(dateFns.subDays(now, 5), "d.MM.yyyy")}`]: units.filter(unit =>
      isSameDay(new Date(unit.finishedAt), dateFns.subDays(now, 5)),
    ),
    [`${dateFns.format(dateFns.subDays(now, 6), "d.MM.yyyy")}`]: units.filter(unit =>
      isSameDay(new Date(unit.finishedAt), dateFns.subDays(now, 6)),
    ),
    [`${dateFns.format(dateFns.subDays(now, 7), "d.MM.yyyy")}`]: units.filter(unit =>
      isSameDay(new Date(unit.finishedAt), dateFns.subDays(now, 7)),
    ),
    " ": units.filter(unit => isBefore(new Date(unit.finishedAt), dateFns.subDays(now, 7))),
  };
};

const getDraggableStyles = (
  style: DraggingStyle | NotDraggingStyle | undefined,
  snapshot: DraggableStateSnapshot,
) => {
  if (!snapshot.isDropAnimating) return style;

  const { moveTo } = snapshot.dropAnimation!;
  const translate = `translate(${moveTo.x}px, ${Math.abs(moveTo.y)}px)`;

  return {
    ...style,
    transform: `${translate}`,
    transition: `all 0.2s 0.2s`,
    opacity: 0,
  };
};

const getTodoUnitColumnSearch = (
  query: QueryParams,
  stageId: UUID,
  attributesKind: StageBoardAttributeKind[],
) =>
  queryString.stringify({
    masterStages: stageId,
    orderBy: "priority",
    pageSize: 999,
    priorities: ["A", "B"].join(","),
    status: "NOT_STARTED",
    isInGroup: "false",
    search: query.todoUnitsSearch,
    attributesKinds: attributesKind.join(","),
  });

const getTodoGroupColumnSearch = (
  query: QueryParams,
  boardFormat: BoardFormat,
  attributesKind: StageBoardAttributeKind[],
) =>
  queryString.stringify({
    attributesKinds: attributesKind.join(","),
    groupByModel: manufacturingStagesConstants.groupByModelDict[boardFormat],
    search: query.todoGroupsSearch,
  });

const getInProgressColumnSearch = (
  query: QueryParams,
  stageId: UUID,
  boardFormat: BoardFormat,
  attributesKind: StageBoardAttributeKind[],
) =>
  queryString.stringify({
    attributesKinds: attributesKind.join(","),
    priorities: boardFormat === BoardFormat.GROUP ? ["A", "B"].join(",") : "",
    showGroups: boardFormat === BoardFormat.GROUP ? "" : "false",
    schemaStageId: stageId,
    status: "IN_PROGRESS",
    search: query.inProgressUnitsGroupsSearch,
  });

const getReadyColumnSearch = (
  query: QueryParams,
  stageId: UUID,
  attributesKind: StageBoardAttributeKind[],
) =>
  queryString.stringify({
    attributesKinds: attributesKind.join(","),
    masterStages: stageId,
    pageSize: 999,
    status: "FINISHED",
    search: query.readyUnitsSearch,
    finishedAtFrom: query.finishedAtFrom,
    finishedAtTo: query.finishedAtTo,
  });

const getNormalUnitsSearch = (query: QueryParams, stageId: UUID) =>
  queryString.stringify({
    attributesKinds: query.attributesKinds,
    masterStages: stageId,
    priorities: query.priorities,
    page: query.page,
    search: query.search,
    ...query,
  });

const getAttributesKinds = (defaultFilters: StageBoardDefaultAttributesKind[]) => {
  return defaultFilters.map(defaultFilter => defaultFilter.attributeKind);
};

const hasFabric = (stageBoardDefaultAttributesKinds: StageBoardDefaultAttributesKind[]) =>
  stageBoardDefaultAttributesKinds.some(
    defaultFilter => defaultFilter.attributeKind === StageBoardAttributeKind.FABRIC,
  );
const hasSize = (stageBoardDefaultAttributesKinds: StageBoardDefaultAttributesKind[]) =>
  stageBoardDefaultAttributesKinds.some(
    defaultFilter => defaultFilter.attributeKind === StageBoardAttributeKind.SIZE,
  );

const isUnitUrgent = (priority: ManufacturingUnitGroupPriority): boolean =>
  priority === ManufacturingUnitGroupPriority.A || priority === ManufacturingUnitGroupPriority.B;

const isUnitCritical = (priority: ManufacturingUnitGroupPriority): boolean =>
  priority === ManufacturingUnitGroupPriority.A;

export const manufacturingStagesUtils = {
  getAttributesKinds,
  getDraggableStyles,
  getInProgressColumnSearch,
  getNormalUnitsSearch,
  getReadyColumnSearch,
  getSortedManufacturingUnitGroups,
  getSortedManufacturingUnits,
  getTodoUnitColumnSearch,
  getTodoGroupColumnSearch,
  hasFabric,
  hasSize,
  isUnitCritical,
  isUnitUrgent,
};
