import { useThresholdToggle } from "hooks";
import { moduleConfig, externalManufacturerModuleConfig } from "../../moduleConfig";
import cx from "classnames";
import { useRouteMatch } from "react-router-dom";
import styles from "./ModuleNavigation.module.css";
import { assertIsDefined } from "utilities/assertIsDefined";
import cuid from "cuid";
import { ExternalManufacturerModule, Module } from "../../moduleTypes/moduleTypes";
import { NavigationHeader } from "./components/NavigationHeader";
import { NavigationBody } from "./components/NavigationBody";
import { FLAVOR } from "CONSTANTS";

interface Props {
  module: string;
}

export const ModuleNavigation = ({ module }: Props) => {
  const isMain = FLAVOR === "main";
  const currentModuleUrl = isMain
    ? Object.entries(moduleConfig).find(config => {
        if (config[0] === module) {
          return config[1].name;
        }
        return undefined;
      })?.[1].url
    : Object.entries(externalManufacturerModuleConfig).find(config => {
        if (config[0] === module) {
          return config[1].name;
        }
        return undefined;
      })?.[1].url;

  const isImportInvoices = useRouteMatch(`${currentModuleUrl}/import-invoices/check`);

  const { shouldBeOpen, isOpen, toggleOpen } = useThresholdToggle();

  const modules = Object.keys(moduleConfig) as Module[];

  const currentModule = modules.find(key => key === module) as Module | undefined;

  const externalManufacturerModules = Object.keys(
    externalManufacturerModuleConfig,
  ) as ExternalManufacturerModule[];

  const externalManufacturerCurrentModule = externalManufacturerModules.find(
    key => key === module,
  ) as ExternalManufacturerModule | undefined;

  const currentModuleName = Object.entries(
    isMain ? moduleConfig : externalManufacturerModuleConfig,
  ).find(config => {
    if (config[0] === module) {
      return config[1].name;
    }
    return undefined;
  })?.[1].name;

  if (isMain) {
    assertIsDefined(currentModule);
  }

  if (FLAVOR === "externalManufacturing") {
    assertIsDefined(externalManufacturerCurrentModule);
  }

  return (
    <div
      className={cx(styles.navLeft, {
        [styles.navLeftClosed]: !shouldBeOpen,
        "d-none": isImportInvoices,
      })}
    >
      <NavigationHeader
        currentModuleName={currentModuleName}
        isOpen={isOpen}
        toggleOpen={toggleOpen}
        shouldBeOpen={shouldBeOpen}
        currentModule={isMain ? currentModule : externalManufacturerCurrentModule}
        module={module}
      />
      <NavigationBody
        currentModule={isMain ? currentModule : externalManufacturerCurrentModule}
        currentModuleUrl={currentModuleUrl}
      />
    </div>
  );
};

export const clickOutsideShortcutsIgnoreClass = "ignore-outside-shortcuts-click-" + cuid();
export const clickOutsideIgnoreClass = "ignore-outside-click-" + cuid();
