import { createPaginatedKey } from "api/keys";

export const manufacturingKeys = {
  manufacturingItemsProgress: createPaginatedKey("manufacturingItemsProgress"),
  indexStages: createPaginatedKey("indexStages"),
  stageComponents: createPaginatedKey("stageComponents"),
  orderDeliveries: createPaginatedKey("orderDeliveries"),
  recipes: createPaginatedKey("recipes"),
  manufacturingPlan: (manufacturingId: string) => ["manufacturingPlan", manufacturingId],
  manufacturingPosition: (route: number) => ["manufacturingPosition", route],
  settings: {
    stageBoardAttributesKind: createPaginatedKey("stageBoardAttributesKind"),
  },
};
