import {
  externalManufacturerModuleConfig,
  moduleConfig,
} from "components/common/moduleNavigation/moduleConfig";
import {
  ExternalManufacturerModule,
  Module,
} from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { assertIsDefined } from "utilities/assertIsDefined";
import styles from "../ModuleNavigation.module.css";
import { FLAVOR } from "CONSTANTS";
import { ModuleLinkRenderer } from "../../moduleLinkRenderer/ModuleLinkRenderer";
import { isModuleLink } from "typePredicates";

interface Props {
  currentModule: Module | ExternalManufacturerModule | undefined;
  currentModuleUrl: string | undefined;
}

export const NavigationBody = ({ currentModule, currentModuleUrl }: Props) => {
  assertIsDefined(currentModule);
  assertIsDefined(currentModuleUrl);

  const isMain = FLAVOR === "main";

  const navigationSections = isMain
    ? moduleConfig[currentModule as Module].navigationSections
    : externalManufacturerModuleConfig[currentModule as ExternalManufacturerModule]
        .navigationSections;

  return (
    <div className={styles.navLeftBody}>
      {navigationSections.map(section => {
        if (isModuleLink(section)) {
          return (
            <ModuleLinkRenderer
              key={section.url}
              moduleLink={section}
              currentModuleUrl={currentModuleUrl}
            />
          );
        }
        return section;
      })}
    </div>
  );
};
