import { filterFactory } from "components/utils/withFilters";
import { getOrderGroupQuery, getSimplifiedOrderGroups } from "api/order-groups/calls";
import { shipmentStatusToTagDict } from "pages/logistics/shippingShipments/useShippingShipmentsColumns";
import { dateFns, getIsoDateFormat, omit } from "utilities";
import { ShipmentStatus } from "api/shipping/models";
import { countryCodes } from "CONSTANTS";
import { Select } from "components/miloDesignSystem/molecules/select";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { useQuery } from "hooks";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { ShippingService, shippingServiceConstants } from "constants/shippingService";
import { Chip } from "components/miloDesignSystem/atoms/chip";

const providerQueryName = "provider";
const externalStatusQueryName = "externalStatuses";

const ExternalShippingStatus = () => {
  const { query, updateQuery } = useQuery();
  const selectedStatuses = query[externalStatusQueryName]?.split(",") || [];
  const shippingService = (query[providerQueryName] as ShippingService) || "";

  const shippingServices = Object.entries(
    omit(shippingServiceConstants, [
      ShippingService.DPD,
      ShippingService.SPT,
      ShippingService.ZADBANO,
    ]),
  ).map(([value, meta]) => ({
    text: meta.name,
    value,
  }));

  return (
    <div>
      <Select
        items={shippingServices.map(shippingService => ({
          value: shippingService.value,
          text: shippingService.text,
          type: MenuItemType.TEXT,
        }))}
        onChange={value => {
          if (value) {
            updateQuery({
              ...query,
              [providerQueryName]: value,
              [externalStatusQueryName]: "",
              page: 1,
            });
            return;
          }
          updateQuery({
            ...query,
            [providerQueryName]: "",
            [externalStatusQueryName]: "",
            page: 1,
          });
        }}
        selected={shippingService || null}
        theme="dark"
      />
      {shippingService ? (
        <div className="d-flex flex-wrap gap-1 mt-3">
          {shippingServiceConstants[shippingService].externalStatus.map(status => (
            <Chip
              key={status}
              label={status}
              onClick={val => {
                if (val) {
                  updateQuery({
                    ...query,
                    [externalStatusQueryName]: [...selectedStatuses, status].toString(),
                    page: 1,
                  });
                  return;
                }

                updateQuery({
                  ...query,
                  [externalStatusQueryName]: selectedStatuses
                    .filter(selectedStatus => selectedStatus !== status)
                    .toString(),
                  page: 1,
                });
              }}
              variant="primary"
              theme="dark"
              selected={Boolean(selectedStatuses.find(selectedStatus => selectedStatus === status))}
            />
          ))}
        </div>
      ) : (
        <Typography fontSize="10" fontWeight="500" color="info200" className="mt-1">
          Wybierz kuriera żeby zobaczyć jego filtry
        </Typography>
      )}
    </div>
  );
};

export const filterList = filterFactory(({ FilterType }) => [
  {
    type: FilterType.DateRangeWithOptions,
    label: "data nadania",
    name: ["fromCalledPickupAt", "toCalledPickupAt"],
    options: [
      {
        label: "DZISIAJ",
        value: [getIsoDateFormat(new Date()), getIsoDateFormat(new Date())],
      },
      {
        label: "WCZORAJ",
        value: [
          getIsoDateFormat(dateFns.subDays(new Date(), 1)),
          getIsoDateFormat(dateFns.subDays(new Date(), 1)),
        ],
      },
      {
        label: "PRZEDWCZORAJ",
        value: [
          getIsoDateFormat(dateFns.subDays(new Date(), 2)),
          getIsoDateFormat(dateFns.subDays(new Date(), 2)),
        ],
      },
    ],
  },
  {
    type: FilterType.DateRangeWithOptions,
    label: "data utworzenia przesyłki",
    name: ["fromCreatedAt", "toCreatedAt"],
    options: [
      {
        label: "DZISIAJ",
        value: [getIsoDateFormat(new Date()), getIsoDateFormat(new Date())],
      },
      {
        label: "WCZORAJ",
        value: [
          getIsoDateFormat(dateFns.subDays(new Date(), 1)),
          getIsoDateFormat(dateFns.subDays(new Date(), 1)),
        ],
      },
      {
        label: "PRZEDWCZORAJ",
        value: [
          getIsoDateFormat(dateFns.subDays(new Date(), 2)),
          getIsoDateFormat(dateFns.subDays(new Date(), 2)),
        ],
      },
    ],
  },
  {
    type: FilterType.Select,
    label: "kolejność wyników",
    name: "ordering",
    kind: "label",
    multiple: false,
    options: [
      {
        value: "-createdAt",
        label: "Daty utworzenia - od najnowszych",
      },
      {
        value: "createdAt",
        label: "Daty utworzenia - od najstarszych",
      },
      {
        value: "-calledPickupAt",
        label: "Daty nadania - od najnowszych",
      },
      {
        value: "calledPickupAt",
        label: "Daty nadania - od najstarszych",
      },
      {
        value: "-shipmentDeadline",
        label: "Terminu realizacji - od najnowszych",
      },
      {
        value: "shipmentDeadline",
        label: "Terminu realizacji - od najstarszych",
      },
    ],
  },
  {
    type: FilterType.Search,
    label: "konto",
    name: "shippingServices",
    multiple: true,
    searchBy: "shippingShippingServices",
  },
  {
    type: FilterType.Select,
    label: "kurier",
    kind: "label",
    name: "providers",
    multiple: true,
    options: Object.entries(shippingServiceConstants).map(([value, meta]) => ({
      label: meta.name,
      value,
    })),
  },
  {
    type: FilterType.AsyncSearch,
    fetchListFrom: getSimplifiedOrderGroups,
    fetchDetailsFrom: getOrderGroupQuery,
    label: "Numer grupy zamówień",
    name: "orderGroups",
    value: "id",
    display: "signature",
    placeholder: "Szukaj grupy",
  },
  {
    type: FilterType.Custom,
    label: "status u kuriera",
    render: ExternalShippingStatus,
    name: externalStatusQueryName,
    filterBarValueRenderer: queryUtils => {
      const { query, updateQuery } = queryUtils;
      const selectedStatuses = query[externalStatusQueryName]?.split(",") || [];
      return selectedStatuses.map(status => ({
        label: status,
        value: status,
        handleRemoveFilter: () => {
          updateQuery({
            [externalStatusQueryName]: selectedStatuses.filter(e => e !== status).toString(),
          });
        },
      }));
    },
  },
  {
    type: FilterType.Select,
    label: "status",
    kind: "label",
    name: "statuses",
    multiple: true,
    options: Object.entries(
      omit(shipmentStatusToTagDict, [
        ShipmentStatus.CREATE_SHIPMENT_SUCCESS,
        ShipmentStatus.CREATE_SHIPMENT_FAILED,
      ]),
    ).map(([value, tagProps]) => ({
      label: tagProps.label,
      value,
    })),
  },
  {
    type: FilterType.Select,
    label: "kraj",
    name: "countryCodes",
    multiple: true,
    kind: "label",
    options: countryCodes.map(({ icon, value }) => ({
      label: value,
      value,
      icon,
    })),
  },
]);
