import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import grid4x4 from "assets/images/moduleNavigationIcons/grid4x4.svg";
import { Dashboard } from "routes/manufacturingNew/Dashboard";

export const deliveryOrders: ModuleLink = {
  url: "delivery-orders",
  label: "Zlecenia do dostawców",
  icon: grid4x4,
  routing: Dashboard,
  isAccessible: false,
};
