import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Formik } from "formik";
import { cx } from "utilities";
import { validationSchema } from "./validationSchema";
import { Button } from "components/miloDesignSystem/atoms/button";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { manufacturersActions } from "api/manufacturers/actions";
import { GetDataFromGusModal } from "components/common/getDataFromGusModal/GetDataFromGusModal";
import { useToastr, useToggle } from "hooks";

interface Props {
  close: () => void;
}

export interface CreateManufacturerSchema {
  name: string;
  fullName: string;
  email: string;
  taxId: string;
}

const initialValues: CreateManufacturerSchema = {
  name: "",
  email: "",
  fullName: "",
  taxId: "",
};

export const CreateManufacturer = ({ close }: Props) => {
  const handleSubmit = manufacturersActions.usePostManufacturer(close);
  const gusModal = useToggle();
  const toastr = useToastr();

  return (
    <Modal
      close={close}
      isOpen
      width={480}
      title={
        <Typography fontSize="20" fontWeight="700">
          Utwórz dostawcę
        </Typography>
      }
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, isSubmitting, isValid, values, setValues }) => (
          <form className={cx({ "was-validated": !isValid })} onSubmit={handleSubmit}>
            <div className="d-flex gap-3 justify-content-between px-3 pb-3">
              <TextField.Form<CreateManufacturerSchema>
                containerClassName="flex-1"
                label="NIP"
                name="taxId"
                placeholder="Wpisz NIP"
              />

              <Button
                variant="gray"
                size="medium"
                className="mt-2"
                onClick={() => {
                  if (!values.taxId) {
                    toastr.open({
                      title: "Brak NIP",
                      text: "W celu pobrania danych z GUS uzupełnij NIP",
                      type: "warning",
                    });
                    return;
                  }
                  gusModal.open();
                }}
              >
                Pobierz dane z GUS
              </Button>

              {gusModal.isOpen && (
                <GetDataFromGusModal
                  modal={gusModal}
                  taxId={values.taxId}
                  onConfirmClick={data =>
                    setValues({
                      ...values,
                      fullName: data.fullName,
                      name: data.ownerLastName,
                    })
                  }
                />
              )}
            </div>
            <div className="px-3 pb-3">
              <TextField.Form<CreateManufacturerSchema>
                label="Pełna nazwa"
                name="fullName"
                placeholder="Wpisz pełną nazwę"
                helperText="Będzie używana do dokumentów finansowych i w oficjalnej korespondencji"
              />
            </div>
            <div className="px-3 pb-3">
              <TextField.Form<CreateManufacturerSchema>
                label="Nazwa skrócona"
                name="name"
                placeholder="Wpisz nazwę skróconą"
                helperText="Będzie wyświetlana w aplikacji"
              />
            </div>
            <div className="px-3 pb-3">
              <TextField.Form<CreateManufacturerSchema>
                label="Email"
                name="email"
                placeholder="Wpisz email"
              />
            </div>

            <div className="d-flex align-items-center gap-2 p-3 borderTop">
              <Button className="text-uppercase" onClick={close} size="medium" variant="gray">
                Anuluj
              </Button>
              <Button
                className="text-uppercase"
                isLoading={isSubmitting}
                size="medium"
                type="submit"
                variant="deepPurple"
              >
                Dodaj
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
};
