import { PageHeader } from "components/common";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { useQuery, useToggle } from "hooks";
import { queryString } from "utilities";
import darkPlusIcon from "assets/images/darkPlus.svg";
import { AddReturnModal } from "./addReturnModal/AddReturnModal";
import { wmsReturnsActions } from "api/wms/returns/actions";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { Table } from "components/miloDesignSystem/molecules/table";
import { ReturnListItem } from "api/wms/returns/models";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { useReturnsColumns } from "./useReturnsColumns";
import { RightPanel } from "./rightPanel/RightPanel";
import { filterFactory, withFilters } from "components/utils/withFilters";
import { wmsReturnConstants } from "constants/returns";

const filterList = filterFactory(({ FilterType }) => [
  {
    type: FilterType.Select,
    kind: "label",
    label: "status",
    name: "status",
    options: Object.entries(wmsReturnConstants.statusOptions).map(([value, details]) => ({
      label: details.label,
      value,
    })),
  },
]);

export const ReturnList = withFilters(filterList, () => {
  const { query } = useQuery({ exclude: ["panelId"] });
  const search = queryString.stringify(query);
  const {
    data: returns,
    error,
    isPreviousData,
    isLoading,
    pagination,
  } = wmsReturnsActions.useGetReturns(search);
  const columns = useReturnsColumns();
  const tableProps = useTableFeatureConnector({
    rows: returns,
    withPagination: { pagination },
    withDrawer: "wmsReturns",
  });
  const addReturnModal = useToggle();

  return (
    <>
      <PageWrapper>
        <PageHeader
          createButton={{
            alt: "utwórz",
            img: darkPlusIcon,
            label: "Utwórz zwrot",
            onClick: addReturnModal.open,
          }}
          searchInput={{
            label: "Szukaj wśród zwrotów",
            tags: [],
          }}
          viewLabel="WMS_RETURNS"
        />
        <div className="d-flex flex-1 overflow-hidden justify-content-between">
          <Table<ReturnListItem>
            columns={columns}
            isLoading={isLoading || isPreviousData}
            error={error}
            uiSchema={mainListUiSchema}
            {...tableProps}
          />
          <RightPanel />
        </div>
      </PageWrapper>
      {addReturnModal.isOpen && <AddReturnModal close={addReturnModal.close} />}
    </>
  );
});
