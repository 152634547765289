import { CommonError, DisabledOpacity, MockupLoader } from "components/utils";
import { DrawerRightPanel, RightPanelBody } from "components/utils/drawer";
import { useQuery } from "hooks";
import { GeneralInfoSection } from "./components/GeneralInfoSection";
import { PanelHeader } from "./components/PanelHeader";
import { manufacturersActions } from "api/manufacturers/actions";

export const RightPanel = () => {
  const { query } = useQuery();
  const { data: manufacturer, error, isLoading } = manufacturersActions.useManufacturer(
    Number(query.panelId),
    {
      enabled: Boolean(query.panelId),
    },
  );

  if (error) {
    return (
      <DrawerRightPanel>
        <CommonError status={error._httpStatus_} />
      </DrawerRightPanel>
    );
  }

  if (isLoading) {
    return (
      <DrawerRightPanel>
        <MockupLoader type="drawer" />
      </DrawerRightPanel>
    );
  }

  if (!manufacturer) return null;

  return (
    <DrawerRightPanel key={manufacturer.id}>
      <PanelHeader />
      <DisabledOpacity disabled={isLoading}>
        <RightPanelBody>
          <GeneralInfoSection />
        </RightPanelBody>
      </DisabledOpacity>
    </DrawerRightPanel>
  );
};
