import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { manufacturersApi } from "./calls";
import { useMutation } from "hooks/useMutation";
import { getAnyErrorKey } from "utilities";
import { CreateManufacturerSchema } from "pages/manufacturingNew/manufacturers/createManufacturer/CreateManufacturer";
import { FormikHelpers } from "formik";
import { createApiQuery } from "hooks/createApiQuery";
import { useRedux } from "hooks";
import { manufacturersKeys } from "./keys";
import { assertIsDefined } from "utilities/assertIsDefined";
import { withDeleteConfirmation } from "hooks/withMutationConfirmation";
import { Manufacturer } from "./models";

const useManufacturers = createPaginatedApiQuery(manufacturersApi.getManufacturersQuery);
const useManufacturer = createApiQuery(manufacturersApi.getManufacturerQuery);

const usePostManufacturer = (close: () => void) => {
  const [dispatch, { partials }] = useRedux();
  const refetchPartials = () => dispatch(partials.fetchPartials());
  const createManufacturer = useMutation(
    manufacturersApi.postManufacturerQuery,
    ({ toastr, queryClient }) => ({
      onSuccess: () => {
        queryClient.invalidateQueries();
        refetchPartials();
        close();
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: "Dodano dostawcę",
        });
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );

  const handleSubmit = (
    values: CreateManufacturerSchema,
    actions: FormikHelpers<CreateManufacturerSchema>,
  ) => {
    createManufacturer.mutate(values, {
      onSuccess: () => {
        actions.setSubmitting(false);
      },
      onError: error => {
        actions.setSubmitting(false);
        actions.setErrors(error.response?.data);
      },
    });
  };

  return handleSubmit;
};

const usePatchManufacturer = () => {
  const [dispatch, { partials }] = useRedux();
  const refetchPartials = () => dispatch(partials.fetchPartials());

  return useMutation(manufacturersApi.patchManufacturerQuery, ({ queryUtils }) => ({
    onMutate: ({ id, ...toUpdate }) => {
      const prevPanel = queryUtils.handleMutate(manufacturersKeys.manufacturer(id), toUpdate);
      const prevList = queryUtils.handlePaginatedListUpdate(
        manufacturersKeys.manufacturers(),
        id,
        toUpdate,
      );
      return { prevList, prevPanel };
    },
    onSuccess: () => {
      refetchPartials();
    },
    onError: (error, { id }, onMutateReturn) => {
      assertIsDefined(onMutateReturn);
      queryUtils.rollback(manufacturersKeys.manufacturer(id), onMutateReturn.prevPanel, error);
      queryUtils.rollbackList(manufacturersKeys.manufacturers(), onMutateReturn.prevList, id);
    },
  }));
};

const useDeleteManufacturer = (close: () => void, manufacturer: Manufacturer) => {
  return withDeleteConfirmation(
    useMutation(manufacturersApi.deleteManufacturerQuery, ({ queryClient, toastr }) => ({
      onSuccess: () => {
        close();
        queryClient.invalidateQueries();
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: `Usunięto dostawcę "${manufacturer.name}"`,
        });
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    })),
    "Czy na pewno chcesz usunąć tego dostawcę?",
  )();
};

export const manufacturersActions = {
  useManufacturers,
  usePostManufacturer,
  useManufacturer,
  usePatchManufacturer,
  useDeleteManufacturer,
};
