import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { RightPanelSection } from "components/utils/drawer/Drawer";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const TitleSection = () => {
  const context = useScrollableContext();

  return (
    <RightPanelSection>
      <div ref={context.triggerElementRef}>
        <Typography fontSize="26" fontWeight="700">
          {EMPTY_VALUE}
        </Typography>
      </div>
    </RightPanelSection>
  );
};
