import { useEffect, useMemo, useState } from "react";
import { MenuStateReturn, useMenuButton, useMenuState } from "reakit";
import { NormalizedItem, Nullable, SearchProps } from "./types";
import v4 from "cuid";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { MenuList } from "components/miloDesignSystem/atoms/menu";
import { MenuItem, MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { cx, omit, queryString } from "utilities";
import { ClickOutsideHandler } from "components/utils";
import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import styles from "./Search.module.css";
import { useFilters } from "hooks/useFilters";
import { Assign } from "utility-types";
import { MdiSearch } from "components/miloDesignSystem/atoms/icons/MdiSearch";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { MdiClose } from "components/miloDesignSystem/atoms/icons/MdiClose";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { InferPaginationItem, MDSFormType } from "typeUtilities";
import { useDebounce } from "hooks";
import { assertIsDefined } from "utilities/assertIsDefined";
import { useField, useFormikContext } from "formik";

interface Filters {
  search: string;
}

export const Search = <TRes extends unknown>({
  fetcherFn,
  onChange,
  disabled,
  textFieldProps,
  theme = "light",
  externalSelectedItem,
  transformQuery,
  isNullable = false,
  normalizeItem,
}: SearchProps<TRes>) => {
  const menu = useMenuState({});
  const menuButton = useMenuButton(menu);
  const clickOutsideIgnoreClass = useMemo(() => `click-outside-select-omit-${v4()}`, []);
  const { filters, setFilter } = useFilters<Filters>({ search: "" });
  const search = useDebounce(
    queryString.stringify({
      ...filters,
      ...transformQuery,
    }),
    400,
  );

  const { data, isFetching } = createPaginatedApiQuery(fetcherFn)(search, {
    enabled: Boolean(menu.visible),
  });
  const [selectedItem, setSelectedItem] = useState<NormalizedItem | null>(
    externalSelectedItem || null,
  );

  useEffect(() => {
    if (externalSelectedItem === undefined) return;
    setSelectedItem(externalSelectedItem);
  }, [externalSelectedItem]);

  const customOnChange = (item: NormalizedItem | null) => {
    if (!item) {
      (onChange as Nullable<TRes>["onChange"])(null);
      setSelectedItem(null);
      return;
    }
    const selectedFoundItem = data.find(_item => {
      const normalizedItem = normalizeItem ? normalizeItem(_item) : getNormalizedSearchItem(_item);
      return normalizedItem.value === item.value;
    });
    assertIsDefined(selectedFoundItem);
    onChange(selectedFoundItem);
    setSelectedItem(item);
  };

  const handleFocus: React.FocusEventHandler<HTMLInputElement> = e => {
    if (disabled) return;
    e.stopPropagation();
    menu.setVisible(true);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter("search", event.currentTarget.value);
  };

  const clearSearchValue = () => {
    setFilter("search", "");
  };

  const handleMenuItemClick = (value: NormalizedItem) => {
    clearSearchValue();
    customOnChange(value);
    menu.hide();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!menu.visible) {
      menu.setVisible(true);
    }
    switch (event.key) {
      case "Up":
      case "ArrowUp":
        event.preventDefault();
        menu.last();
        return;
      case "Down":
      case "ArrowDown":
        event.preventDefault();
        menu.first();
        return;
      case "Enter":
        event.preventDefault();

        const normalizedItem = normalizeItem
          ? normalizeItem(data[0])
          : getNormalizedSearchItem(data[0]);
        if (normalizedItem) {
          handleMenuItemClick(normalizedItem);
        }
        return;
      case "Esc":
      case "Escape":
        event.preventDefault();
        clearSearchValue();
        menu.hide();
        return;
    }
  };

  return (
    <ClickOutsideHandler
      onClickOutside={() => {
        menu.setVisible(false);
        clearSearchValue();
      }}
      outsideClickIgnoreClass={clickOutsideIgnoreClass}
    >
      <div
        ref={menuButton.ref}
        onClick={() => {
          if (!menu.visible && !disabled) {
            menu.setVisible(true);
            menu.first();
          }
        }}
      >
        <TextField
          EndInputSection={
            <EndInputSection
              theme={theme}
              clearInputValue={() => {
                clearSearchValue();
                customOnChange(null);
              }}
              showLoader={isFetching && menu.visible}
              hasSelectedValue={Boolean(selectedItem)}
              isNullable={isNullable}
            />
          }
          disabled={disabled}
          inputClassName={cx(
            { [styles[`selectedPlaceholder-${theme}`]]: Boolean(selectedItem) && !disabled },
            clickOutsideIgnoreClass,
          )}
          containerClassName={clickOutsideIgnoreClass}
          theme={theme}
          endIcon={
            <MdiSearch color={theme === "light" ? "neutralBlack48" : "neutralWhite48"} size="18" />
          }
          value={filters.search}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          size="default"
          onFocus={handleFocus}
          {...textFieldProps}
          placeholder={selectedItem?.text || textFieldProps?.placeholder || "Szukaj..."}
        />
        <SearchMenuList
          handleMenuItemClick={handleMenuItemClick}
          data={data}
          normalizedSearchItem={selectedItem}
          menu={menu}
          searchValue={filters.search}
          className={clickOutsideIgnoreClass}
          normalizeItem={normalizeItem}
        />
      </div>
    </ClickOutsideHandler>
  );
};

const SearchMenuList = <TRes extends unknown>({
  className,
  searchValue,
  menu,
  normalizedSearchItem,
  data,
  handleMenuItemClick,
  normalizeItem,
}: {
  className: string;
  searchValue: string;
  menu: MenuStateReturn;
  normalizedSearchItem: NormalizedItem | null;
  data: InferPaginationItem<TRes>[];
  normalizeItem?: (item: InferPaginationItem<TRes>) => NormalizedItem;
  handleMenuItemClick: (value: NormalizedItem) => void;
}) => {
  const parsedMenuItems: Assign<Omit<MenuItem, "onClick">, { value: string }>[] = data.map(item => {
    const normalizedItem = normalizeItem ? normalizeItem(item) : getNormalizedSearchItem(item);
    return { ...normalizedItem, type: MenuItemType.TEXT };
  });

  const menuItems = parsedMenuItems.map(menuItem => {
    const isSelected = normalizedSearchItem?.value === menuItem.value;
    return {
      ...menuItem,
      options: {
        ...menuItem.options,
        className: isSelected ? styles.selected : "",
      },
      onClick: () => {
        assertIsDefined(menuItem.value);

        handleMenuItemClick({ text: menuItem.text, value: menuItem.value });
      },
    } as MenuItem;
  });

  return (
    <MenuList
      searchValue={searchValue}
      className={className}
      hideOnClickOutside={false}
      menuItems={menuItems}
      menuState={menu}
    />
  );
};

const EndInputSection = ({
  showLoader,
  hasSelectedValue,
  clearInputValue,
  isNullable,
  theme,
}: {
  showLoader: boolean;
  hasSelectedValue: boolean;
  clearInputValue: () => void;
  isNullable: boolean;
  theme: "light" | "dark";
}) => {
  return (
    <div className="d-flex align-items-center gap-1">
      <div className={styles.showLoader}>{showLoader && <Spinner size={18} />}</div>
      {hasSelectedValue && isNullable && (
        <IconButton
          icon={MdiClose}
          theme={theme}
          variant="transparent"
          size="small"
          onClick={event => {
            event.stopPropagation();
            clearInputValue();
          }}
        />
      )}
    </div>
  );
};

function getNormalizedSearchItem(item: any): NormalizedItem {
  const fallback = "Brak obsługi pola text";
  const text = (() => {
    if (item?.signature) return item.signature;

    if (item?.name) return item.name;

    if (item?.firstName && item?.lastName) return `${item.firstName} ${item.lastName}`;

    return fallback;
  })();

  return { text, value: String(item.id) };
}

function FormSearch<TForm, TRes>(
  props: Assign<
    MDSFormType<SearchProps<TRes>, TForm>,
    { customOnChange?: (value: InferPaginationItem<TRes>) => void }
  >,
) {
  const [field, meta] = useField(props.name as string);
  const propsToForward = omit(props, ["name"]);
  const { setFieldValue } = useFormikContext<TForm>();

  return (
    <Search
      {...propsToForward}
      {...field}
      onChange={(value: any) => {
        if (props.customOnChange) {
          return props.customOnChange(value);
        }
        return setFieldValue(props.name as string, value ? value.id : value);
      }}
      textFieldProps={
        ({
          error: meta.touched && meta.error,
          ...props.textFieldProps,
        } as unknown) as SearchProps<TRes>["textFieldProps"]
      }
    />
  );
}

Search.Form = FormSearch;
