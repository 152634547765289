import { indexesActions } from "api/manufacturing/indexes/actions";
import { IndexListItem } from "api/manufacturing/indexes/models";
import { Button } from "components/miloDesignSystem/atoms/button/Button";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiPackage } from "components/miloDesignSystem/atoms/icons/MdiPackage";
import { Popover } from "components/miloDesignSystem/atoms/popover/Popover";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip/Tooltip";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { ClickOutsideHandler } from "components/utils/ClickOutsideHandler";
import { ErrorMessage } from "components/utils";
import { useState } from "react";

interface Props {
  indexes: IndexListItem[];
}

export const IndexesPackagesNumber = ({ indexes }: Props) => {
  const packagesNumberMutation = indexesActions.useBulkUpdateIndexesPackagesNumber();
  const [numberOfPackages, setNumberOfPackages] = useState(0);

  return (
    <Popover
      content={({ hide }) => (
        <ClickOutsideHandler
          onClickOutside={() => {
            hide();
            setNumberOfPackages(0);
          }}
          outsideClickIgnoreClass={ignoreClickOutsideClassName}
        >
          <div>
            <Typography className="mt-2 mb-3" fontSize="14" fontWeight="700">
              Zmień liczbę paczek
            </Typography>
            <div className="mb-3">
              <TextField
                label="Liczba paczek"
                onChange={event => setNumberOfPackages(Number(event.target.value))}
                placeholder="Liczba paczek"
                type="number"
                value={String(numberOfPackages)}
              />
              {numberOfPackages < 0 && (
                <Typography color="danger600" fontSize="16" fontWeight="600" noWrap>
                  <ErrorMessage text="Liczba paczek nie może być ujemna" type="text" />
                </Typography>
              )}
            </div>
            <div className="mt-3">
              <Button
                className="text-uppercase w-100"
                disabled={numberOfPackages < 0 || packagesNumberMutation.isLoading}
                onClick={() =>
                  packagesNumberMutation.mutate(
                    {
                      indexesIds: indexes.map(_index => _index.id),
                      numberOfPackages,
                    },
                    {
                      onSuccess: () => {
                        hide();
                        setNumberOfPackages(0);
                      },
                    },
                  )
                }
                size="small"
                variant="deepPurple"
              >
                Zmień liczbę paczek
              </Button>
            </div>
          </div>
        </ClickOutsideHandler>
      )}
      hideOnClickOutside={false}
      variant="DARK"
    >
      <div>
        <Tooltip title="Zmień liczbę paczek">
          <IconButton
            icon={MdiPackage}
            isLoading={packagesNumberMutation.isLoading}
            theme="dark"
            variant="transparent"
          />
        </Tooltip>
      </div>
    </Popover>
  );
};

const ignoreClickOutsideClassName = "indexes-list-bulk-packages-number-ignore-class-name";
