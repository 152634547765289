import { EmployeeKind } from "CONSTANTS";
import { ManufacturingEmployeeListItem } from "api/manufacturingNew/models";
import { upholsteryFileFactory } from "api/upholsteries/calls";
import { useDownloadFeedbackToastr } from "components/utils/downloadFeedback/DownloadFeedbackController";
import { fileDownloader } from "fileDownloader";
import { useErrorToastr } from "hooks/useErrorToastr";
import { useMutation } from "hooks/useMutation";
import { ManufacturingEmployeeDetails } from "./models";
import { manufacturingEmployeesApi } from "./api";
import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { createApiQuery } from "hooks/createApiQuery";
import { withDeleteConfirmation } from "hooks/withMutationConfirmation";
import { getAnyErrorKey } from "utilities";
import { manufacturingEmployeesKeys } from "./keys";
import { assertIsDefined } from "utilities/assertIsDefined";

const useManufacturingEmployees = createPaginatedApiQuery(
  manufacturingEmployeesApi.getManufacturingEmployees,
);
const useManufacturingEmployee = createApiQuery(manufacturingEmployeesApi.getManufacturingEmployee);

const useDownloadEmployeeLabel = () => {
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const handleErrorMessage = useErrorToastr();

  async function handleDownloadEmployeeLabel(employee: ManufacturingEmployeeListItem) {
    const tstr = downloadFeedbackToastr.open({ type: "pdf" });
    const { url, name } = upholsteryFileFactory.employeeLabel({
      ...employee!,
      kind: employee!.kind as EmployeeKind,
    });
    const response = await fileDownloader({
      onProgress: tstr.updateProgress,
      url,
      name,
      type: "pdf",
    });
    if (response.status === "success") {
      tstr.lazyClose();
    } else {
      tstr.close();
      handleErrorMessage(response);
    }
  }

  return handleDownloadEmployeeLabel;
};

const usePatchManufacturingEmployee = () => {
  return useMutation(manufacturingEmployeesApi.patchManufacturingEmployee, ({ queryUtils }) => ({
    onMutate: ({ id, data }) => {
      const prevPanel = queryUtils.handleMutate(
        manufacturingEmployeesKeys.details(String(id)),
        data,
      );
      const prevList = queryUtils.handlePaginatedListUpdate(
        manufacturingEmployeesKeys.list(),
        id,
        data,
      );
      return { prevList, prevPanel };
    },
    onError: (error, { id }, onMutateReturn) => {
      assertIsDefined(onMutateReturn);
      queryUtils.rollback(
        manufacturingEmployeesKeys.details(String(id)),
        onMutateReturn.prevPanel,
        error,
      );
      queryUtils.rollbackList(manufacturingEmployeesKeys.list(), onMutateReturn.prevList, id);
    },
  }));
};

const useRemoveManufacturingEmployee = (
  close: () => void,
  employee: ManufacturingEmployeeDetails,
) => {
  return withDeleteConfirmation(
    useMutation(
      manufacturingEmployeesApi.deleteManufacturingEmployee,
      ({ queryClient, toastr }) => ({
        onSuccess: () => {
          close();
          queryClient.invalidateQueries();
          toastr.open({
            type: "success",
            title: "Udało się!",
            text: `Usunięto dostawcę "${employee.firstName} ${employee.lastName}"`,
          });
        },
        onError: error => {
          toastr.open({
            type: "warning",
            title: "Wymagane działanie",
            text: getAnyErrorKey(error),
          });
        },
      }),
    ),
    "Czy na pewno chcesz usunąć tego pracownika?",
  )();
};

export const manufacturingEmployeesActions = {
  useDownloadEmployeeLabel,
  useManufacturingEmployee,
  useManufacturingEmployees,
  usePatchManufacturingEmployee,
  useRemoveManufacturingEmployee,
};
