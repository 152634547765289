import { ManufacturingUnitListViewDetails, UnitStatus } from "api/manufacturing/units/models";
import { InfoLabel } from "components/common/infoLabel";
import { MdiFabric } from "components/miloDesignSystem/atoms/icons/MdiFabric";
import { Tag } from "components/miloDesignSystem/atoms/tag/Tag";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelSection } from "components/utils/drawer";
import { manufacturingStagesConstants } from "constants/manufacturingStages";
import { dateFns } from "utilities";
import {
  EMPTY_VALUE,
  EmptyValue,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { getScheduledAtLabel } from "../../useUnitColumns";
import { Link } from "components/miloDesignSystem/atoms/link";
import { CollectionLink } from "./CollectionLink";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { manufacturingUnitsActions } from "api/manufacturing/units/actions";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { Select } from "components/miloDesignSystem/molecules/select/Select";
import { OrderLink } from "./OrderLink";

interface Props {
  unitDetails: ManufacturingUnitListViewDetails;
}

export const GeneralInfoSection = ({ unitDetails }: Props) => {
  const unitStatusOptions = Object.entries(manufacturingStagesConstants.unitStatusOptions).map(
    ([value, details]) => ({
      text: details.label,
      type: MenuItemType.TEXT,
      value,
    }),
  );

  if (!unitDetails) return null;

  return (
    <div className="d-flex flex-column">
      <div className="py-2 px-3">
        <div className="pb-2">
          <Typography fontSize="20" fontWeight="700">
            {unitDetails.manufacturingItem.name || EMPTY_VALUE}
          </Typography>
        </div>
      </div>
      <RightPanelSection padding="px-3 pt-0">
        <div className="pb-2">
          <InfoLabel title="tkanina">
            <div className="d-flex align-items-center gap-2 justify-content-between">
              <div className="d-flex align-items-center gap-1 flex-1 cut-text">
                {Boolean(unitDetails.fabrics.length) && <MdiFabric size="14" />}
                <Typography fontSize="14" fontWeight="600" noWrap>
                  {unitDetails.fabrics[0]?.name}
                </Typography>
                {unitDetails.fabrics.length >= 2 && (
                  <Typography color="deepPurple400" fontSize="12" fontWeight="700">
                    +{unitDetails.fabrics.length - 1}
                  </Typography>
                )}
              </div>
              {unitDetails.materialStatus && (
                <Tag
                  label={
                    manufacturingStagesConstants.materialsStatusOptions[unitDetails.materialStatus]
                      .label
                  }
                  variant={
                    manufacturingStagesConstants.materialsStatusOptions[unitDetails.materialStatus]
                      .variant
                  }
                />
              )}
            </div>
          </InfoLabel>
        </div>
        <div className="pb-2">
          <InfoLabel title="przygotować do">
            {unitDetails.scheduledAt ? (
              <div className="d-flex align-items-center gap-1">
                <Typography fontSize="14" fontWeight="600">
                  {dateFns.format(new Date(unitDetails.scheduledAt), "d.MM")}
                </Typography>
                {getScheduledAtLabel(unitDetails.scheduledAt) && (
                  <Typography
                    color={getScheduledAtLabel(unitDetails.scheduledAt)!.color}
                    fontSize="14"
                    fontWeight="600"
                  >
                    ({getScheduledAtLabel(unitDetails.scheduledAt)!.label})
                  </Typography>
                )}
              </div>
            ) : (
              <EmptyValue fontSize="14" fontWeight="700" />
            )}
          </InfoLabel>
        </div>
        <div className="pb-2">
          <InfoLabel title="poprzedni etap">
            {!Boolean(unitDetails.previousStages.length) ? (
              <EmptyValue fontSize="16" fontWeight="700" />
            ) : (
              <div className="d-flex align-items-center gap-1">
                <Tag label={unitDetails.previousStages[0].name} type="outlined" variant="warning" />
                <Tag
                  label={
                    manufacturingStagesConstants.stageStatusOptions[
                      unitDetails.previousStages[0].status
                    ].label
                  }
                  type={
                    manufacturingStagesConstants.stageStatusOptions[
                      unitDetails.previousStages[0].status
                    ].type
                  }
                  variant={
                    manufacturingStagesConstants.stageStatusOptions[
                      unitDetails.previousStages[0].status
                    ].variant
                  }
                />
                {unitDetails.previousStages.length >= 2 && (
                  <Typography color="deepPurple400" fontSize="12" fontWeight="700">
                    +{unitDetails.previousStages.length - 1}
                  </Typography>
                )}
              </div>
            )}
          </InfoLabel>
        </div>
        <div className="pb-2">
          <InfoLabel title="w etapie od">
            <Typography fontSize="14" fontWeight="400">
              {unitDetails.startedAt
                ? dateFns.formatRelative(new Date(unitDetails.startedAt), "dd.MM.yyyy, H:mm")
                : EMPTY_VALUE}
            </Typography>
          </InfoLabel>
        </div>
        <div className="pb-2">
          <InfoLabel title="status">
            <Select.Async
              items={unitStatusOptions}
              mutationHook={manufacturingUnitsActions.useListUnitPatch}
              transformQueryData={status => ({
                id: unitDetails.id,
                toUpdate: {
                  status: status as UnitStatus,
                },
              })}
              selected={unitDetails.status}
            />
          </InfoLabel>
        </div>
        <div className="pb-2">
          <InfoLabel title="lokalizacja">
            <Typography fontSize="14" fontWeight="400">
              {unitDetails.location || EMPTY_VALUE}
            </Typography>
          </InfoLabel>
        </div>
        <div className="pb-2">
          <OrderLink unitDetails={unitDetails} />
        </div>
        <div className="pb-2">
          <CollectionLink unitDetails={unitDetails} />
        </div>
        <div className="pb-2">
          <InfoLabel title="plan produkcji">
            {unitDetails.manufacturingSchema ? (
              <Link
                fontSize="14"
                fontWeight="500"
                to={`/manufacturing/manufacturing-plans/list/all?panelId=${unitDetails.manufacturingSchema.id}`}
              >
                {unitDetails.manufacturingSchema.signature}
              </Link>
            ) : (
              <EmptyValue fontSize="14" fontWeight="500" />
            )}
          </InfoLabel>
        </div>
        <div className="pb-3">
          <TextField.Async
            label="Notatka"
            mutationHook={manufacturingUnitsActions.useListUnitPatch}
            transformQueryData={note => ({
              id: unitDetails.id,
              toUpdate: {
                note,
              },
            })}
            value={unitDetails.note}
          />
        </div>
      </RightPanelSection>
    </div>
  );
};
