import { useCarrierOrders } from "api/logistics/carrierOrders/hooks";
import { CarrierOrder } from "api/logistics/carrierOrders/models";
import { PageHeader } from "components/common";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { Table } from "components/miloDesignSystem/molecules/table";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { useQuery } from "hooks";
import { queryString } from "utilities";
import { useCarrierOrdersListColumns } from "./hooks/useCarrierOrdersListColumns";
import { RightPanel } from "./rightPanel/RightPanel";
import {
  DrawerHelpers,
  getTablePropsBasedOnDrawerHelpers,
} from "components/utils/drawerHelpers/DrawerHelpers";
import { filterFactory, withFilters } from "components/utils/withFilters";

const filterList = filterFactory(({ FilterType }) => [
  {
    type: FilterType.SelectUser,
    kind: "carrier",
    label: "przewoźnik",
    multiple: true,
    name: "carrier",
    searchBy: "users",
  },
]);

export const CarrierOrdersList = withFilters(filterList, () => {
  const { query, updateQuery } = useQuery({ exclude: ["panelId"] });
  const search = queryString.stringify(query);

  const { data: carrierOrders, error, isLoading, pagination } = useCarrierOrders(search);

  const columns = useCarrierOrdersListColumns();

  return (
    <PageWrapper>
      <PageHeader
        searchInput={{
          label: "Szukaj wśród zleceń do przewoźników",
          tags: [],
        }}
        viewLabel="LOGISTICS_CARRIER_ORDERS"
      />
      <div className="d-flex flex-1 overflow-hidden justify-content-between">
        <DrawerHelpers name="logisticsCarriersOrders">
          {drawerHelpers => (
            <>
              <Table<CarrierOrder>
                rows={carrierOrders}
                columns={columns}
                onPaginationChange={paginationState => {
                  updateQuery({ ...query, page: paginationState.pageIndex });
                }}
                isLoading={isLoading}
                error={error}
                pagination={pagination}
                uiSchema={mainListUiSchema}
                {...getTablePropsBasedOnDrawerHelpers(drawerHelpers)}
              />

              <RightPanel />
            </>
          )}
        </DrawerHelpers>
      </div>
    </PageWrapper>
  );
});
