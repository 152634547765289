import { PostOrderManufacturingMaterial } from "api/manufacturing/materials/models";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { useSelector } from "hooks";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { Select } from "components/miloDesignSystem/molecules/select";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";

export const useOrderMaterialColumns = () => {
  const manufacturers = useSelector(state => state.partials.manufacturers);
  return useCreateTableColumns<PostOrderManufacturingMaterial["orders"][number]>(
    ({ columnHelper }) => {
      return [
        columnHelper.text(row => row.name, {
          header: "nazwa",
          size: 200,
          typographyProps: {
            color: "neutralBlack88",
            fontSize: "16",
          },
        }),
        columnHelper.text(row => row.category?.name ?? null, {
          header: "kategoria",
          size: 200,
          typographyProps: {
            color: "neutralBlack88",
            fontSize: "16",
            fontWeight: "500",
          },
        }),
        columnHelper.accessor(row => row, {
          header: "ilość materiału",
          size: 150,
          cell: info => {
            const row = info.getValue();
            return (
              <div className="d-flex align-items-center gap-1">
                <TextField.Form
                  size="small"
                  name={`orders[${info.row.index}].quantity`}
                  containerClassName="w-50"
                />

                <Typography fontSize="14" fontWeight="500" color="neutralBlack48">
                  {row.unit.shortName}
                </Typography>
              </div>
            );
          },
        }),
        columnHelper.accessor(row => row, {
          header: "dostawca",
          size: 183,
          cell: info => {
            return (
              <div className="position-relative">
                <Select.Form
                  items={manufacturers.map(manufacturer => ({
                    text: manufacturer.name,
                    type: MenuItemType.TEXT,
                    value: manufacturer.id,
                  }))}
                  textFieldProps={{ size: "small" }}
                  name={`orders[${info.row.index}].manufacturer`}
                />
              </div>
            );
          },
        }),
      ];
    },
    { shouldDisplayIndexColumn: true },
  );
};
