import { useQuery, useToggle } from "hooks";
import { ListHeader } from "./components/ListHeader";
import { getSearch } from "./utils/getSearch";
import { RouteComponentProps } from "react-router";
import { EmployeesTab as Tab } from "api/manufacturingNew/models";
import { CreateManufacturingEmployeeModal } from "./components/CreateManufacturingEmployeeModal";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { Table } from "components/miloDesignSystem/molecules/table";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { useEmployeesColumns } from "./useEmployeesColumns";
import { RightPanel } from "./rightPanel/RightPanel";
import { manufacturingEmployeesActions } from "api/manufacturing/employees/actions";
import { ManufacturingEmployeeListItem } from "api/manufacturing/employees/models";

export const EmployeesList = ({ match }: RouteComponentProps<{ tab: Tab }>) => {
  const { tab } = match.params;
  const { query } = useQuery({ exclude: ["panelId"] });
  const search = getSearch({ query, tab });
  const createManufacturingEmployeeModal = useToggle(false);
  const {
    data: employees,
    error,
    isLoading,
    isPreviousData,
    pagination,
  } = manufacturingEmployeesActions.useManufacturingEmployees(search);

  const columns = useEmployeesColumns();

  const tableProps = useTableFeatureConnector({
    rows: employees,
    withDrawer: "manufacturingEmployeesList",
    withPagination: { pagination },
  });

  return (
    <PageWrapper>
      <ListHeader open={createManufacturingEmployeeModal.open} />
      <div className="d-flex flex-1 overflow-hidden justify-content-between">
        <Table<ManufacturingEmployeeListItem>
          columns={columns}
          error={error}
          isLoading={isLoading || isPreviousData}
          uiSchema={mainListUiSchema}
          {...tableProps}
        />
        <RightPanel />
      </div>
      {createManufacturingEmployeeModal.isOpen && (
        <CreateManufacturingEmployeeModal close={createManufacturingEmployeeModal.close} />
      )}
    </PageWrapper>
  );
};
