import { MdiCheck } from "components/miloDesignSystem/atoms/icons/MdiCheck";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { ManufacturingOrder } from "api/orders/models";
import { RouteManufacturingStatus } from "api/routes/models";
import { ReactNode } from "react";

export const useOrderListColumns = () => {
  return useCreateTableColumns<ManufacturingOrder>(
    ({ columnHelper }) => {
      return [
        columnHelper.text(row => `${row.delivery.firstName} ${row.delivery.lastName}`, {
          header: "klient",
          size: 142,
        }),
        columnHelper.text(
          row => `${row.delivery.address.postCode} ${row.delivery.address.street}`,
          {
            header: "adres",
            size: 142,
            typographyProps: { fontSize: "12" },
          },
        ),
        columnHelper.text(row => row.signature, {
          header: "zamówienie",
          size: 142,
          typographyProps: { fontSize: "12" },
        }),
        columnHelper.accessor(row => row.manufacturingStatus, {
          header: "status produkcji",
          size: 142,
          cell: info => {
            const manufacturingStatus = info.getValue();
            return manufacturingStatusToTagDict[manufacturingStatus];
          },
        }),
        // columnHelper.accessor(row => row, {
        //   header: "dokumenty",
        //   id: "labels",
        //   size: 70,
        //   cell: info => {
        //     const row = info.getValue();
        //     return (
        //       <div className="d-flex align-items-center gap-1">
        //         <IconButton
        //           icon={MdiDownloadPdf}
        //           onClick={event => {
        //             event.stopPropagation();
        //             //@ts-ignore
        //             downloadOrderPdf(row);
        //           }}
        //           variant="transparent"
        //         />
        //       </div>
        //     );
        //   },
        // }),
      ];
    },
    { shouldDisplayIndexColumn: true },
  );
};

const manufacturingStatusToTagDict: Record<RouteManufacturingStatus, ReactNode> = {
  [RouteManufacturingStatus.FINISHED]: <Tag startIcon={MdiCheck} label="ok" variant="success" />,
  [RouteManufacturingStatus.IN_PROGRESS]: <Tag label="trwa" variant="warning" />,
  [RouteManufacturingStatus.PENDING]: (
    <Tag label="nie rozpoczęto" variant="quaternary" type="outlined" />
  ),
};
