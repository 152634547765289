import { ManufacturingUnitListViewItem } from "api/manufacturing/units/models";
import { TableToolbar } from "components/miloDesignSystem/molecules/tableToolbar/TableToolbar";
import { AssignEmployee } from "./assignEmployee/AssignEmployee";

interface Props {
  close: () => void;
  numberOfSelectedItems: number;
  units: ManufacturingUnitListViewItem[];
}

export const StagesTableToolbar = ({ close, numberOfSelectedItems, units }: Props) => {
  return (
    <TableToolbar close={close} numberOfSelectedItems={numberOfSelectedItems}>
      <AssignEmployee units={units} />
    </TableToolbar>
  );
};
