import { TableToolbar } from "components/miloDesignSystem/molecules/tableToolbar/TableToolbar";
import { AssignManufacturingSchema } from "./assignManufacturingSchema/AssignManufacturingSchema";
import { IndexListItem } from "api/manufacturing/indexes/models";
import { IndexesPackagesNumber } from "./indexesPackagesNumber/IndexesPackagesNumber";
import { BindPackages } from "./customMadePackages/BindPackages";
import { DetachPackages } from "./customMadePackages/DetachPackages";

interface Props {
  close: () => void;
  indexes: IndexListItem[];
  numberOfSelectedItems: number;
}

export const IndexesTableToolbar = ({ close, indexes, numberOfSelectedItems }: Props) => {
  return (
    <TableToolbar close={close} numberOfSelectedItems={numberOfSelectedItems}>
      <AssignManufacturingSchema indexes={indexes} />
      <IndexesPackagesNumber indexes={indexes} />
      <BindPackages indexes={indexes} />
      <DetachPackages indexes={indexes} />
    </TableToolbar>
  );
};
