import { WalletEmployeeTransaction } from "api/manufacturing/wallet/models";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";
import { Button } from "components/miloDesignSystem/atoms/button";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiChevronRight } from "components/miloDesignSystem/atoms/icons/MdiChevronRight";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { manufacturingEmployeesUtils } from "utilities/manufacturingEmployees";
import { MdiDownloadPdf } from "../../../../../components/miloDesignSystem/atoms/icons/MdiDownloadPdf";
import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const useTransactionsColumns = () => {
  return useCreateTableColumns<WalletEmployeeTransaction>(
    ({ columnHelper }) => {
      return [
        columnHelper.date(row => row.createdAt, {
          header: "data",
          size: 70,
          textAlign: "left",
          typographyProps: {
            fontSize: "12",
            fontWeight: "500",
          },
        }),
        columnHelper.amount(
          row => String(manufacturingEmployeesUtils.transfromCentsToPln(row.amount)),
          {
            header: "kwota",
            size: 135,
            amountDisplayProps: {
              currency: row => row.original.currency,
            },
            textAlign: "right",
          },
        ),
        columnHelper.amount(
          row =>
            String(manufacturingEmployeesUtils.transfromCentsToPln(row.balanceAfterTransaction)),
          {
            header: "saldo",
            size: 135,
            amountDisplayProps: {
              currency: row => row.original.currency,
            },
            textAlign: "right",
          },
        ),
        columnHelper.accessor(row => row.createdBy, {
          header: "dodane przez",
          size: 100,
          cell: info => {
            const user = info.getValue();
            if (!user) return <EmptyValue fontWeight="700" />;
            return (
              <div className="d-flex align-items-center gap-1">
                <Avatar size="sm" user={user} />
                <Typography fontSize="12" fontWeight="700" noWrap>
                  {user.firstName} {user.lastName}
                </Typography>
              </div>
            );
          },
        }),
        // TODO: waiting for backend
        columnHelper.accessor(row => row, {
          header: "typ płatności",
          size: 90,
          cell: () => {
            return <EmptyValue />;
          },
        }),
        columnHelper.accessor(row => row, {
          id: "settlement",
          header: () => (
            <Typography
              className="text-right w-100"
              color="neutralBlack48"
              fontSize="12"
              fontWeight="400"
            >
              rozliczenie
            </Typography>
          ),
          size: 100,
          cell: () => {
            return (
              <div className="d-flex align-items-center gap-1">
                <Button
                  className="text-uppercase"
                  disabled
                  endIcon={MdiChevronRight}
                  onClick={event => event.stopPropagation()}
                  size="small"
                  variant="gray"
                >
                  Pokaż
                </Button>
                <IconButton
                  disabled
                  icon={MdiDownloadPdf}
                  onClick={event => event.stopPropagation()}
                  variant="gray"
                />
              </div>
            );
          },
        }),
      ];
    },
    {
      shouldDisplayIndexColumn: true,
    },
  );
};
