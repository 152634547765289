import { manufacturingEmployeesActions } from "api/manufacturing/employees/actions";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { Button } from "components/miloDesignSystem/atoms/button";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton/IconButton";
import { MdiDelete } from "components/miloDesignSystem/atoms/icons/MdiDelete";
import { MdiDownloadPdf } from "components/miloDesignSystem/atoms/icons/MdiDownloadPdf";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { MdiMoreHoriz } from "components/miloDesignSystem/atoms/icons/MdiMoreHoriz";
import { Menu } from "components/miloDesignSystem/atoms/menu/Menu";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { RightPanelHeader, SlidingHeaderWrapper } from "components/utils/drawer";
import { useQuery } from "hooks";
import { useDrawer } from "hooks/useDrawer";

export const PanelHeader = () => {
  const { query } = useQuery();
  const { panelId } = query;
  const { close } = useDrawer("manufacturingEmployeesList");
  const { data: employee } = manufacturingEmployeesActions.useManufacturingEmployee(panelId, {
    enabled: Boolean(panelId),
  });
  const { isTriggerElementHidden } = useScrollableContext();
  const removeEmployeeMutation = manufacturingEmployeesActions.useRemoveManufacturingEmployee(
    close,
    employee!,
  );

  if (!employee) return null;

  return (
    <RightPanelHeader>
      {isTriggerElementHidden ? (
        <SlidingHeaderWrapper>
          <Typography fontSize="16" fontWeight="600" noWrap>
            {employee.firstName} {employee.lastName}
          </Typography>
        </SlidingHeaderWrapper>
      ) : (
        <div />
      )}
      <div className="d-flex align-items-center justify-content-end gap-1">
        <Button
          className="text-uppercase"
          disabled
          size="small"
          startIcon={MdiDownloadPdf}
          variant="gray"
        >
          Pobierz raport dzienny
        </Button>
        <Menu
          disclosure={<IconButton icon={MdiMoreHoriz} variant="transparent" />}
          menuItems={[
            {
              type: MenuItemType.TEXT,
              options: {
                color: "red100",
                iconStart: MdiDelete,
              },
              onClick: () => removeEmployeeMutation.mutate(Number(employee.id)),
              text: "Usuń",
            },
          ]}
        />
        <span className="divider line-divider" />
        <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
      </div>
    </RightPanelHeader>
  );
};
