import styled from "@emotion/styled";
import { indexesActions } from "api/manufacturing/indexes/actions";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { Tabs } from "components/miloDesignSystem/molecules/tabs";
import { CommonError } from "components/utils";
import { SectionWrapper } from "pages/crm/customers/customerDetails/components/sectionWrapper/SectionWrapper";
import { useParams } from "react-router";
import { getAnyErrorKey } from "utilities";
import { assertIsDefined } from "utilities/assertIsDefined";
import { GeneralInformationTab } from "./tabs/generalInformationTab/GeneralInformationTab";
import { RecipesTab } from "./tabs/recipesTab/recipesTab";
import { ProductionSchemaTab } from "./tabs/productionStagesTab/ProductionSchemaTab";

export const IndexTabSection = () => {
  const { indexId } = useParams<{ indexId: string }>();
  const { data: indexDetails, error, isLoading } = indexesActions.useIndex(indexId);

  if (isLoading)
    return (
      <SectionWrapper
        className="mb-3 d-flex flex-1 justify-content-center align-items-center"
        width="100%"
        minHeight={516}
      >
        <Spinner size={30} />
      </SectionWrapper>
    );

  if (error)
    return (
      <SectionWrapper
        className="mb-3 d-flex flex-1 justify-content-center align-items-center"
        width="100%"
        minHeight={516}
      >
        <CommonError status={error._httpStatus_} text={getAnyErrorKey(error)} />
      </SectionWrapper>
    );

  assertIsDefined(indexDetails);

  return (
    <SectionWrapper className="mb-3 pt-3 d-flex flex-column flex-1 overflow-hidden">
      <Tabs mode="state">
        <div className="d-flex gap-2 px-3">
          <Tabs.TabItem label="Receptura" value={1} />
          <Tabs.TabItem label="Schemat produkcji" value={2} />
          <Tabs.TabItem label="Paczki" value={3} />
          <Tabs.TabItem label="Numery obce" value={4} />
          <Tabs.TabItem label="Dane ogólne" value={5} />
        </div>
        <StyledLine />
        <Tabs.TabContentRenderer value={1}>
          <RecipesTab />
        </Tabs.TabContentRenderer>
        <Tabs.TabContentRenderer value={2}>
          <ProductionSchemaTab />
        </Tabs.TabContentRenderer>
        <Tabs.TabContentRenderer value={5}>
          <GeneralInformationTab />
        </Tabs.TabContentRenderer>
      </Tabs>
    </SectionWrapper>
  );
};

const StyledLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--neutralBlack16);
  margin-top: -1px;
`;
