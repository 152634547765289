import { RightPanel } from "./rightPanel/RightPanel";
import { useQuery, useToggle } from "hooks";
import { useManufacturingStages } from "api/manufacturingNew/hooks";
import { AddManufacturingStage } from "./addManufacturingStage/AddManufacturingStage";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import darkPlusIcon from "assets/images/darkPlus.svg";
import { PageHeader } from "components/common";
import { queryString } from "utilities";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { Table } from "components/miloDesignSystem/molecules/table";
import { ManufacturingStage } from "api/manufacturing/schemas/models";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { useManufacturingStagesColumns } from "./useManufacturingStagesColumns";

export const ManufacturingStagesList = () => {
  const { query } = useQuery({ exclude: ["panelId", "unitPanelId"] });
  const search = queryString.stringify(query);
  const addManufacturingStageModal = useToggle();
  const {
    data: manufacturingStages,
    error,
    isLoading,
    isPreviousData,
    pagination,
  } = useManufacturingStages(search);
  const tableProps = useTableFeatureConnector({
    rows: manufacturingStages,
    withDrawer: "manufacturingStagesList",
    withPagination: { pagination },
  });
  const columns = useManufacturingStagesColumns();

  return (
    <PageWrapper>
      <PageHeader
        createButton={{
          alt: "Plus",
          img: darkPlusIcon,
          label: "Utwórz etap produkcyjny",
          onClick: addManufacturingStageModal.open,
        }}
        searchInput={{
          label: "Szukaj wśród etapów produkcyjnych",
          tags: [],
        }}
        viewLabel="MANUFACTURING_STAGES"
      />
      <div className="d-flex flex-1 overflow-hidden justify-content-between">
        <Table<ManufacturingStage>
          columns={columns}
          error={error}
          isLoading={isLoading || isPreviousData}
          uiSchema={mainListUiSchema}
          {...tableProps}
        />
        <RightPanel />
      </div>
      {addManufacturingStageModal.isOpen && (
        <AddManufacturingStage close={addManufacturingStageModal.close} />
      )}
    </PageWrapper>
  );
};
