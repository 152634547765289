import { ISODate, ISODateTime, UUID } from "api/types";

export type MaterialsTab = "in-use" | "withdrawn";

export enum MaterialStatus {
  IN_USE = "IN_USE",
  WITHDRAWN = "WITHDRAWN",
}

export enum MaterialAvailabilityStatus {
  HIGH = "HIGH",
  LOW = "LOW",
  VERY_HIGH = "VERY_HIGH",
  VERY_LOW = "VERY_LOW",
}

export type MaterialsListSearch = {
  tab: MaterialsTab;
  query: {
    [x: string]: string;
  };
};

export interface MaterialListItem {
  category: {
    id: number;
    name: string;
  } | null;
  deliveryDays: number;
  externalId: string;
  group: {
    id: UUID;
    name: string;
  } | null;
  id: UUID;
  name: string;
  packageSize: string;
  averageDeliveryDays: number;
  providers: {
    id: UUID;
    isMain: boolean;
    name: string;
  }[];
  size: string;
  kind: ManufacturingMaterialKind;
  series: string;
  status: MaterialStatus;
  unit: {
    shortName: string;
    name: string;
  };
  isCustomOrdered: boolean;
  stock: {
    inStock: number;
    demandFromOrders: number;
    stockStatus: MaterialAvailabilityStatus;
    alreadyOrdered: number;
    lastWeekDemand: number;
  };
}

export interface Material {
  id: UUID;
  createdAt: ISODateTime;
  name: string;
  category: {
    id: UUID;
    name: string;
  } | null;
  unit: {
    id: UUID;
    name: string;
    shortName: string;
  };
  kind: ManufacturingMaterialKind;
  series: string;
  status: MaterialStatus;
  externalId: string;
  averageDeliveryDays: number;
  providers: MaterialListItem["providers"];
  isCustomOrdered: boolean;
  stock: MaterialListItem["stock"];
}

export enum ManufacturingMaterialKind {
  "RAW" = "RAW",
  "SEMI_FINISHED" = "SEMI_FINISHED",
}

export interface MaterialCategory {
  createdAt: ISODateTime;
  id: UUID;
  name: string;
}

export interface MaterialUnit {
  createdAt: ISODateTime;
  id: UUID;
  name: string;
  shortName: string;
}

export interface PostManufacturingMaterial {
  name: string;
  unit: UUID;
  category: UUID | null;
  externalId: string;
  kind: ManufacturingMaterialKind;
  averageDeliveryDays: number | null;
  manufacturer: { id: number; isMain: boolean } | null;
}

export interface PostOrderManufacturingMaterial {
  defaultScheduledDeadline: boolean;
  delivery: number | null;
  areEmailLabelsRequired: boolean;
  orders: {
    id: UUID;
    name: MaterialListItem["name"];
    category: MaterialListItem["category"];
    unit: MaterialListItem["unit"];
    material: UUID;
    manufacturer: UUID | null;
    quantity: string;
    scheduledDeadline: ISODate | null;
  }[];
}
export enum MaterialStockUpdateDirection {
  IN = "IN",
  OUT = "OUT",
}

export interface ManualMaterialStockUpdatePayload {
  direction: MaterialStockUpdateDirection;
  quantity: number;
}
